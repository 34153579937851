// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-error-js": () => import("./../../../src/templates/error.js" /* webpackChunkName: "component---src-templates-error-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-policies-js": () => import("./../../../src/templates/policies.js" /* webpackChunkName: "component---src-templates-policies-js" */)
}

